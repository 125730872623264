import AWS from "aws-sdk";
import Lambda from "aws-sdk/clients/lambda";
import { Auth } from "@aws-amplify/auth";

var appConfiguration: any = (window as any).app_configuration;


export const getDispositions = async (filter: any) => {
    try {
        AWS.config.update({ region: appConfiguration.region });
        const credentials = await Auth.currentCredentials();
        // console.log("credentials", credentials);
        let result: any = [];
        const lambda = new Lambda({
            credentials: Auth.essentialCredentials(credentials),
        });
        const response: any = await lambda.invoke({
            FunctionName: appConfiguration.reader_lambda_name,
            Payload: JSON.stringify(filter)
        }).promise();
        const { Payload } = response;
        const parsedPayload = JSON.parse(response ? Payload : "{}")
        if (parsedPayload && Object.keys(parsedPayload).length) {
            const { data } = parsedPayload;
            result = JSON.parse(data ? data : "{}")
        }
        // console.log("responseresponse", JSON.parse(response ? response.Payload : ""));
        // console.log("result", result);
        return result;
    } catch (error) {
        console.log("Error getDispositions ===>", error)
        return [];
    }
}

export const getSurveyResults = async (filter: any) => {
    try {
        AWS.config.update({ region: appConfiguration.region });
        const credentials = await Auth.currentCredentials();
        // console.log("credentials", credentials);
        let result: any = [];
        const lambda = new Lambda({
            credentials: Auth.essentialCredentials(credentials),
        });
        const response: any = await lambda.invoke({
            FunctionName: appConfiguration.reader_lambda_name,
            Payload: JSON.stringify(filter)
        }).promise();
        const { Payload } = response;
        const parsedPayload = JSON.parse(response ? Payload : "{}")
        if (parsedPayload && Object.keys(parsedPayload).length) {
            const { data } = parsedPayload;
            result = JSON.parse(data ? data : "{}")
        }
        // console.log("responseresponse", JSON.parse(response ? response.Payload : ""));
        // console.log("result", result);
        return result;
    } catch (error) {
        console.log("Error getSurveyResults ===>", error)
        return [];
    }
}