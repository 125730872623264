export const getTransformedDispositions = (data) => {
  const dispositionCounts = {};
  data.forEach((obj) => {
    if (
      obj.Disposition &&
      obj.Disposition.length &&
      obj.Disposition?.toLowerCase() !== "null"
    ) {
      const disposition = obj.Disposition;
      if (disposition) {
        if (dispositionCounts.hasOwnProperty(disposition)) {
          dispositionCounts[disposition]++;
        } else {
          dispositionCounts[disposition] = 1;
        }
      }
    }
  });

  const dispositionArray = Object.keys(dispositionCounts).map((disposition) => {
    return { title: disposition, value: dispositionCounts[disposition] };
  });
  console.log("dispositionArray", dispositionArray);
  return dispositionArray;
};

export const getLastTwoMonthsDates = () => {
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: January is 0, so we add 1

  const lastMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;
  const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  const lastTwoMonthsYear = lastMonth === 1 ? lastMonthYear - 1 : lastMonthYear;
  const lastTwoMonths = lastMonth === 1 ? 12 : lastMonth - 1;

  const startDate = new Date(lastTwoMonthsYear, lastTwoMonths - 1, 1); // Note: Month is 0-based
  const endDate = new Date(lastMonthYear, lastMonth, 0); // Last day of last month

  const formattedStartDate = startDate.toISOString().split("T")[0];
  const formattedEndDate = endDate.toISOString().split("T")[0];

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  };
};

export const getTransformedResults = (arr) => {
  const counts = new Map();

  arr.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (/^SurveyResult\d+$/.test(key) && !isNaN(obj[key])) {
        const count = counts.get(key) || 0;
        counts.set(key, count + 1);
      }
    });
  });

  const result = [];

  counts.forEach((count, key) => {
    if (count === 1) {
      result.push({ [key]: key });
    } else {
      result.push({ [key]: count });
    }
  });

  return result;
};

export const filterResultEntries = (arr, key) => {
  const result = arr.filter((obj) => {
    for (let i = 1; i <= 5; i++) {
      if (
        obj[key] === null ||
        obj[key] === "" ||
        obj[key] === undefined ||
        obj[key] === "NULL"
      ) {
        return false;
      }
    }
    return true;
  });
  console.log(arr, result);
  return result;
};
