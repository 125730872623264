import {
    Box,
    Button,
    Checkbox,
    Container,
    DateRangePicker,
    ExpandableSection,
    Form,
    FormField,
    Header,
    Input,
    PieChart,
    SpaceBetween,
    Tabs,
    Spinner
} from "@awsui/components-react";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { SurveyModel } from "../../models/SurveyModel";
import { getDispositions } from "../../service";
import { getTransformedDispositions } from "../../utils";
import "./disposition-listing.css";

interface DispositionListingProps {
    authenticated: (value: boolean) => void
}

const DispositionListing: FC<DispositionListingProps> = (props) => {
    const nav = useNavigate();

    const [id, setId] = useState("");
    const [title, setTitle] = useState("");
    const [intro, setIntro] = useState("");
    const [outro, setOutro] = useState("");
    const [minimum, setMinimum] = useState(0);
    const [maximum, setMaximum] = useState(9);
    const [questions, setQuestions] = useState<any[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [results, setResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [dateRangeFilter, setDateRangeFilter] = React.useState<any>({});
    const [data, setData] = React.useState<any>([]);
    const [gettingData, setGettingData] = React.useState<boolean>(false);

    var appConfiguration: any = (window as any).app_configuration;

    const validateRange = () => {
        if (maximum <= minimum || minimum >= maximum) {
            return false;
        }

        return true;
    };

    const refreshData = async () => {
        if (dateRangeFilter && Object.keys(dateRangeFilter).length) {
            setGettingData(true);
            const rawResult = await getDispositions(dateRangeFilter);
            setGettingData(false);
            setFilteredResults(rawResult);
        } else {
            setFilteredResults(results);
        }
    }
    const filterResults = async (value: any) => {
        if (value !== null) {
            if (value.endDate === "") {
                value.endDate = value.startDate;
            }
            setDateRangeFilter(value);
            console.log("date value", value)
            setGettingData(true);
            const response = await getDispositions(value);
            setGettingData(false);
            console.log("response response", response)
            const transformedDispositions = getTransformedDispositions(response);
            setData(transformedDispositions);
        }
        // if (value !== null) {
        //     if (value.endDate === "") {
        //         value.endDate = value.startDate;
        //     }

        //     setDateRangeFilter(value);

        //     let startDate = new Date(value.startDate + " 00:00:00").getTime() / 1000;
        //     let endDate = value.endDate !== "" ? new Date(value.endDate + " 23:59:59").getTime() / 1000 : new Date(value.startDate + " 23:59:59").getTime() / 1000;

        //     let newFilteredResults = results.filter((o: any) => o.timestamp >= startDate && o.timestamp <= endDate);
        //     setFilteredResults(newFilteredResults);
        // } else {
        //     setFilteredResults(results);
        // }

    }


    // const getCallDispositions = async () => {
    //     await getDispositions();
    // };

    // useEffect(() => {
    //     getCallDispositions()
    // }, [])

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((data) => {
                sessionStorage.setItem("jwt", data.signInUserSession.idToken.jwtToken);
                props.authenticated(true);
            }).catch(() => {
                nav("/login");
            });
    }, []);

    return (
        <>
            {/* <Tabs
                tabs={[
                    {
                        label: "Results",
                        content: (
                            <>
                                <SpaceBetween size="m">
                                    <DateRangePicker
                                        key="daterange"
                                        onChange={({ detail }) => filterResults(detail.value)}
                                        value={dateRangeFilter}
                                        isValidRange={() => {
                                            return { valid: true };
                                        }}
                                        dateOnly
                                        rangeSelectorMode="absolute-only"
                                        relativeOptions={[
                                            {
                                                key: "previous-24-hours",
                                                amount: 1,
                                                unit: "minute",
                                                type: "relative",
                                            },
                                            {
                                                key: "previous-30-minutes",
                                                amount: 30,
                                                unit: "minute",
                                                type: "relative",
                                            },
                                            {
                                                key: "previous-1-hour",
                                                amount: 1,
                                                unit: "hour",
                                                type: "relative",
                                            },
                                            {
                                                key: "previous-6-hours",
                                                amount: 6,
                                                unit: "hour",
                                                type: "relative",
                                            },
                                        ]}
                                        i18nStrings={{
                                            todayAriaLabel: "Today",
                                            nextMonthAriaLabel: "Next month",
                                            previousMonthAriaLabel: "Previous month",
                                            customRelativeRangeDurationLabel: "Duration",
                                            customRelativeRangeDurationPlaceholder: "Enter duration",
                                            customRelativeRangeOptionLabel: "Custom range",
                                            customRelativeRangeOptionDescription: "Set a custom range in the past",
                                            customRelativeRangeUnitLabel: "Unit of time",
                                            formatRelativeRange: (e) => {
                                                const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                                                return `Last ${e.amount} ${t}`;
                                            },
                                            formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                                            dateTimeConstraintText: "",
                                            relativeModeTitle: "Relative range",
                                            absoluteModeTitle: "Absolute range",
                                            relativeRangeSelectionHeading: "Choose a range",
                                            startDateLabel: "Start date",
                                            endDateLabel: "End date",
                                            startTimeLabel: "Start time",
                                            endTimeLabel: "End time",
                                            clearButtonLabel: "Clear and dismiss",
                                            cancelButtonLabel: "Cancel",
                                            applyButtonLabel: "Apply",
                                        }}
                                        placeholder="Filter by a date range"
                                    />
                                    <PieChart
                                        hideFilter={true}
                                        ariaLabel="Pie chart"
                                        data={data}
                                    />
                                </SpaceBetween>
                            </>
                        ),
                        id: "results",
                    },
                ]}
            /> */}

            <SpaceBetween size="m">
                <div className="range-and-loader">
                    <DateRangePicker
                        className={`${gettingData ? "range-disable" : ""}`}
                        key="daterange"
                        onChange={({ detail }) => filterResults(detail.value)}
                        value={dateRangeFilter}
                        isValidRange={() => {
                            return { valid: true };
                        }}
                        dateOnly
                        rangeSelectorMode="absolute-only"
                        relativeOptions={[
                            {
                                key: "previous-24-hours",
                                amount: 1,
                                unit: "minute",
                                type: "relative",
                            },
                            {
                                key: "previous-30-minutes",
                                amount: 30,
                                unit: "minute",
                                type: "relative",
                            },
                            {
                                key: "previous-1-hour",
                                amount: 1,
                                unit: "hour",
                                type: "relative",
                            },
                            {
                                key: "previous-6-hours",
                                amount: 6,
                                unit: "hour",
                                type: "relative",
                            },
                        ]}
                        i18nStrings={{
                            todayAriaLabel: "Today",
                            nextMonthAriaLabel: "Next month",
                            previousMonthAriaLabel: "Previous month",
                            customRelativeRangeDurationLabel: "Duration",
                            customRelativeRangeDurationPlaceholder: "Enter duration",
                            customRelativeRangeOptionLabel: "Custom range",
                            customRelativeRangeOptionDescription: "Set a custom range in the past",
                            customRelativeRangeUnitLabel: "Unit of time",
                            formatRelativeRange: (e) => {
                                const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                                return `Last ${e.amount} ${t}`;
                            },
                            formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                            dateTimeConstraintText: "",
                            relativeModeTitle: "Relative range",
                            absoluteModeTitle: "Absolute range",
                            relativeRangeSelectionHeading: "Choose a range",
                            startDateLabel: "Start date",
                            endDateLabel: "End date",
                            startTimeLabel: "Start time",
                            endTimeLabel: "End time",
                            clearButtonLabel: "Clear and dismiss",
                            cancelButtonLabel: "Cancel",
                            applyButtonLabel: "Apply",
                        }}
                        placeholder="Filter by a date range"
                    />
                    {gettingData ?
                        <Spinner className="range-loader" /> :
                        <Button iconName="refresh" variant="icon" onClick={refreshData}>
                            OK
                        </Button>}
                </div>

                <PieChart
                    hideFilter={true}
                    ariaLabel="Pie chart"
                    data={data}
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No data available</b>
                            <Box variant="p" color="inherit">
                                There is no data available
                            </Box>
                        </Box>
                    }
                    noMatch={
                        <Box textAlign="center" color="inherit">
                            <b>No matching data</b>
                            <Box variant="p" color="inherit">
                                There is no matching data to display
                            </Box>
                            {/* <Button>Clear filter</Button> */}
                        </Box>
                    }
                />
            </SpaceBetween>
        </>
    );
};

export default DispositionListing;
