import React, { createContext, useEffect, useState, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";

// interface Utility {
//     type: string,
//     text: string,
//     href: string,
//     external: boolean
// }

interface AppContextType {
    isAuthenticated: boolean;
    // utilities: Utility[];
}

const AppContext = createContext<AppContextType | undefined>(undefined);

const AppContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // const [utilities, setUtilities] = useState<Utility[]>([]);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    // const nav = useNavigate();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((data) => {
                sessionStorage.setItem("jwt", data.signInUserSession.idToken.jwtToken);
                setIsAuthenticated(true);
            })
            .catch((err) => {
                // nav("/login");
            });
    }, []);

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         setUtilities([
    //             { type: "button", text: "Logout", href: "/logout", external: false },
    //         ]);
    //     } else {
    //         setUtilities([]);
    //     }
    // }, [isAuthenticated]);

    return (
        <AppContext.Provider
            value={{
                isAuthenticated,
                // utilities,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

export { AppContextProvider, AppContext };
